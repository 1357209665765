import { useOrgLanguages } from 'components/Org/components'

import getFlag from 'utils/getFlag'

import useAdvertTranslation from '../useAdvertTranslation'

import { IAdvertLanguagesComponent } from './types'

export const AdvertLanguages = ({
  translation,
  languages
}: IAdvertLanguagesComponent) => {
  const { t, lang } = useAdvertTranslation({
    ns: ['ad'],
    translation
  })

  const { translatedLanguages } = useOrgLanguages({
    languages,
    lang
  })

  return (
    <section className="py-5 ">
      <div className="">
        <div className="">
          <h2 className="mb-8 text-2xl font-bold tracking-wide">
            {t('languagesTitle', { ns: 'ad' })}
            <span className="dot"></span>
          </h2>
        </div>
        <div className="flex flex-wrap gap-4">
          {translatedLanguages?.map((lang) => {
            // INFO: may have errrors: if lang.language.value, and lang.language is {value: string, label: string}
            const language = lang?.name
            const languageLevel = lang?.languageLevel
            const flag = getFlag(language)
            return (
              <span
                key={language}
                className="break-all rounded-full border border-slate-500 p-2"
              >
                {flag && <i className={`fp fp-md fp-rounded ${flag}`}></i>}
                <span className="ml-2 mr-1 ">
                  {lang?.label}
                  {languageLevel && ` - ${languageLevel}`}
                </span>
              </span>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default AdvertLanguages
