import Image from 'next/image'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import { CandidateStatus, SizeVariant } from 'enums'

import { Button } from 'components/buttons'
import { IconArrow, IconClose } from 'icons'

import { getLocalisedLink } from 'utils'

import { IAdvertHeaderProps } from './types'
import useAdvertHeader from './useAdvertHeader'

export const AdvertHeader = ({
  id,
  slug,
  brandName,
  mainPictureUrl,
  visibilityType,
  title,
  apply,
  print
}: IAdvertHeaderProps) => {
  const {
    showApplyBtn,
    isApplied,
    isRejected,
    isLoadingUpdateCandidateStatus,
    isCreatingApplicationLoading,
    t,
    onApply,
    locale,
    onUpdateMatchStatus
  } = useAdvertHeader({
    id,
    slug,
    visibilityType,
    apply
  })

  return (
    <div className="relative min-h-[200px] print:min-h-[120px] sm:min-h-[350px] md:min-h-[450px] lg:w-full">
      {mainPictureUrl && (
        <Image
          src={mainPictureUrl}
          alt={`${brandName ?? ''} organisation picture`}
          fill
          className={clsx('object-contain object-center-top')}
        />
      )}
      <div className="absolute top-0 right-0 z-[3] flex print:hidden">
        <button
          type="button"
          className="bg-white/80 px-5 py-3 font-semibold uppercase transition-colors hover:bg-white/70 active:bg-white/90"
          onClick={async () => {
            await navigator.clipboard.writeText(
              window.location.host + getLocalisedLink('advert', locale) + slug
            )
            toast(t('toast.linkCopied', { ns: 'actions' }))
          }}
        >
          {t('copyLink', { ns: 'actions' })}
        </button>
        {print ? (
          <button
            className="justify-center bg-white/80 px-5 py-3 font-semibold uppercase transition-colors hover:bg-white/70 active:bg-white/90"
            onClick={() => {
              window.print()
            }}
          >
            {t('print', { ns: 'actions' })}
          </button>
        ) : (
          <button
            className="block justify-center bg-white/80 px-5 py-3 font-semibold uppercase transition-colors hover:bg-white/70 active:bg-white/90"
            onClick={() => {
              window.open(`/${locale}/print/ad/${id}`, '_blank')
            }}
          >
            {t('print', { ns: 'actions' })}
          </button>
        )}
      </div>
      <div className="absolute bottom-0 left-0 z-[2] flex h-auto w-full flex-col items-end justify-end gap-x-5 gap-y-4 bg-white/60 py-6 px-5 print:relative md:h-fit md:flex-row md:justify-between md:py-12 md:px-20">
        <h1 className="flex-0 w-full text-2xl font-bold leading-tight md:flex-[4] md:text-4xl">
          {title}
        </h1>
        {(showApplyBtn.btn || showApplyBtn.rejectBtn) && (
          <div className="flex">
            {!isRejected && (
              <Button
                isLoading={isCreatingApplicationLoading}
                disabled={isApplied}
                size={SizeVariant.Medium}
                onClick={onApply}
              >
                {t(isApplied ? 'applied' : 'apply', { ns: 'actions' })}
                <span className="ml-2">
                  <IconArrow />
                </span>
              </Button>
            )}
            {showApplyBtn.rejectBtn && (
              <Button
                isLoading={isLoadingUpdateCandidateStatus}
                size={SizeVariant.Medium}
                onClick={onUpdateMatchStatus(
                  isRejected ? CandidateStatus.Active : CandidateStatus.Rejected
                )}
                className="ml-2"
              >
                {t(isRejected ? 'cancelReject' : 'reject', { ns: 'actions' })}
                <span className="ml-2">{isRejected ? '' : <IconClose />}</span>
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AdvertHeader
