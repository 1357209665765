import { useRouter } from 'next/router'
import { useMemo } from 'react'

import useAdvertTranslation from '../useAdvertTranslation'

import { IAdvertDescriptionComponent } from './types'

export const AdvertDescription = ({
  translation,
  description,
  descriptions
}: IAdvertDescriptionComponent) => {
  const { t } = useAdvertTranslation({
    ns: ['ad', 'inputs'],
    translation
  })
  const { locale } = useRouter()

  const advertDescription = useMemo(() => {
    const desc = descriptions?.find((item) => item.languageCode === locale)
    return desc?.value ?? description
  }, [locale, description, descriptions])

  if (!advertDescription) {
    return null
  }

  return (
    <section className="py-5">
      <h2 className="mb-2 text-2xl font-bold tracking-wide">
        {t('description', { ns: 'ad' })}
        <i className="dot"></i>
      </h2>
      <p className="whitespace-pre-wrap">{advertDescription}</p>
    </section>
  )
}

export default AdvertDescription
