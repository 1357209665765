import create from 'zustand'
import { persist } from 'zustand/middleware'

export enum ActionType {
  Apply
}

export interface ISettings {
  redirectTo?: string
  hasAction?: ActionType | null

  setRedirectTo: (val?: string) => void
  setHasAction: (val?: ActionType | null) => void
  clearStore: () => void
}

const useSettingsStore = create<ISettings>()(
  persist(
    (set) => ({
      redirectTo: undefined,
      hasAction: undefined,

      setRedirectTo: (val?: string) => set(() => ({ redirectTo: val })),
      setHasAction: (val?: ActionType | null) =>
        set(() => ({ hasAction: val })),
      clearStore: () =>
        set(() => ({ redirectTo: undefined, hasAction: undefined }))
    }),
    {
      name: 'hrizer-settings',
      getStorage: () => {
        return localStorage
      }
    }
  )
)

export default useSettingsStore
