import Image from 'next/image'
import { useMemo } from 'react'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { youtubeUrlValidator } from 'validators/orgStepValidator'
import { getYoutubeCode } from 'utils'

import 'swiper/css/pagination'

import useAdvertTranslation from '../useAdvertTranslation'

import { IAdvertOrganisationProps } from './types'

import 'swiper/css'

export const AdvertOrganisation = ({
  description,
  descriptions,
  brandName,
  translation,
  gallery
}: IAdvertOrganisationProps) => {
  const { t, lang } = useAdvertTranslation({
    ns: ['ad'],
    translation
  })

  const galleryContent = useMemo(() => {
    const youtube =
      gallery?.filter((url) => youtubeUrlValidator.safeParse(url).success) ?? []
    return {
      youtube: youtube[0] ?? null,
      images: gallery?.filter((url) => !youtube.includes(url)) ?? []
    }
  }, [gallery])

  const descriptionText = useMemo(() => {
    const desc =
      descriptions?.find((item) => item.languageCode === lang) ??
      descriptions?.find((item) => item.isDefaultValue)

    return desc?.value ?? description
  }, [descriptions, lang, description])

  return (
    <div className="pb-10">
      <section className="w-full py-5">
        <h2 className="mb-2 text-2xl font-bold tracking-wide">
          {t('companyTitle', { ns: 'ad' })}
          <i className="dot"></i>
        </h2>
        <p className="whitespace-pre-wrap">{descriptionText}</p>
      </section>
      {galleryContent.images.length > 0 ? (
        <section className="mx-auto h-60 w-[80vw] max-w-[1140px] overflow-hidden md:w-full">
          <h4 className="mb-2 text-lg font-bold tracking-wide">
            {t('companyGallery', { ns: 'ad' })}
            <i className="dot"></i>
          </h4>
          <Swiper
            spaceBetween={20}
            slidesPerView="auto"
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20
              }
            }}
            className="fade-in-delay overflow-hidden"
            style={{
              width: '100%',
              height: '100%'
            }}
            autoHeight={true}
            modules={[Autoplay]}
            autoplay
          >
            <div className="z-1">
              {galleryContent.images.map((img, index) => (
                <SwiperSlide
                  key={img}
                  style={{ height: '100%' }}
                  className="fade-in-delay"
                >
                  <Image
                    loading="lazy"
                    src={img ?? ''}
                    alt={`${brandName ?? ''} gallery picture ${index}`}
                    className="object-cover object-right text-xs lg:object-contain"
                    fill
                  />
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </section>
      ) : null}
      {galleryContent.youtube && (
        <section className="w-full py-5">
          <h4 className="mb-2 text-lg font-bold tracking-wide">
            {t('companyVideo', { ns: 'ad' })}
            <i className="dot"></i>
          </h4>
          <iframe
            width="100%"
            height="100%"
            className="aspect-video"
            src={`https://www.youtube.com/embed/${getYoutubeCode(
              galleryContent.youtube
            )}`}
            title={`${brandName} company video`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </section>
      )}
    </div>
  )
}

export default AdvertOrganisation
