import { IAdvertBulletProps } from './types'

export const AdvertBullet = ({
  icon: Icon,
  type,
  desc
}: IAdvertBulletProps) => {
  return (
    <div className="flex items-center md:text-base">
      <Icon className="mr-2 h-8 w-8" />
      {type && (
        <span className="mr-3 flex-1 font-semibold md:flex-none">{type}:</span>
      )}
      <span className="flex-[2]">{desc}</span>
    </div>
  )
}

export default AdvertBullet
