import { useMemo } from 'react'

import { formatYearExperience } from 'utils/formaters.util'

import useAdvertTranslation from '../useAdvertTranslation'

import { IAdvertSkillsComponent } from './types'

export const AdvertSkills = ({
  translation,
  competencies,
  drivingLicence
}: IAdvertSkillsComponent) => {
  const { t } = useAdvertTranslation({
    ns: ['ad'],
    translation
  })
  const skillsLeveled = useMemo(() => {
    return {
      MandatorySkills:
        competencies?.filter((s) => s.priority === 'Mandatory') || [],
      PreferableSkills:
        competencies?.filter((s) => s.priority != 'Mandatory') || []
    }
  }, [competencies])
  return (
    <section className="py-5 ">
      <h2 className="mb-4 text-2xl font-bold tracking-wide">
        {t('skillsTitle', { ns: 'ad' })}
        <span className="dot"></span>
      </h2>
      {skillsLeveled.MandatorySkills.length > 0 && (
        <div className="">
          <h4 className="mb-4 text-lg font-semibold tracking-wide">
            {t('skillsMandatoryTitle', { ns: 'ad' })}
          </h4>
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            {drivingLicence && (
              <span className="rounded-full border border-slate-500 p-2  px-4">
                🚗 {t('drivingLicenseSkill', { ns: 'ad' })}
              </span>
            )}
            {skillsLeveled.MandatorySkills.map((s) => (
              <span
                key={s.skillId}
                className="rounded-full border border-slate-500 p-2 px-4"
              >
                {`${s.skill ?? ''} ${formatYearExperience(
                  s.yearsOfExperience
                )}`}
              </span>
            ))}
          </div>
        </div>
      )}
      {skillsLeveled.PreferableSkills.length > 0 && (
        <div className="mt-5">
          <h4 className="mb-4 text-lg font-semibold tracking-wide">
            {t('skillsOptionalTitle', { ns: 'ad' })}
          </h4>
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            {skillsLeveled.PreferableSkills.map((s) => (
              <span
                key={s.skillId}
                className="rounded-full border border-slate-500 p-2 px-4"
              >
                {`${s.skill ?? ''} ${formatYearExperience(
                  s.yearsOfExperience
                )}`}
              </span>
            ))}
          </div>
        </div>
      )}
    </section>
  )
}

export default AdvertSkills
