import { useRouter } from 'next/router'
import { useMemo } from 'react'

import useAdvertTranslation from '../useAdvertTranslation'

import { IAdvertFunctionsComponent } from './types'

export const AdvertFunctions = ({
  translation,
  responsibilities
}: IAdvertFunctionsComponent) => {
  const { locale } = useRouter()
  const { t } = useAdvertTranslation({
    ns: ['ad'],
    translation
  })

  const funcs = useMemo(() => {
    const filteredFunctions = responsibilities?.filter(
      (resp) => resp.languageCode === locale
    )
    let functions = responsibilities
    if (filteredFunctions?.length) {
      functions = filteredFunctions
    }
    return functions?.map((resp) => <li key={resp.value}>{resp.value}</li>)
  }, [responsibilities, locale])

  return (
    <section className="py-5 ">
      <h2 className="mb-2 text-2xl font-bold tracking-wide">
        {t('responsabilitiesTitle', { ns: 'ad' })}
        <i className="dot"></i>
      </h2>
      <ul className="list-inside list-disc">{funcs}</ul>
    </section>
  )
}

export default AdvertFunctions
