import Image from 'next/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { FC, useMemo } from 'react'
import { AdvertVisibilityType } from 'enums'

import { IconClose, IconDraft, IconPause } from 'icons'

import { IAdvertHeadProps } from './types'

interface IShowVisibilityType {
  tStatus: string
  icon: FC
}
const visibilityStatusMap: Record<
  AdvertVisibilityType,
  IShowVisibilityType | null
> = {
  [AdvertVisibilityType.Active]: null,
  [AdvertVisibilityType.Draft]: { tStatus: 'draft', icon: IconDraft },
  [AdvertVisibilityType.Finished]: { tStatus: 'finished', icon: IconClose },
  [AdvertVisibilityType.Paused]: { tStatus: 'paused', icon: IconPause },
  [AdvertVisibilityType.PausedBySubscription]: {
    tStatus: 'pausedBySubscription',
    icon: IconPause
  }
}

export const AdvertHead = ({
  brandName,
  logoUrl,
  slug,
  visibilityType,
  settings,
  print = false,
  showVisibilityStatus
}: IAdvertHeadProps) => {
  const { t } = useTranslation(['actions'])

  const visibilityAdvertType = useMemo(() => {
    const status = visibilityStatusMap[visibilityType]
    if (!status) {
      return null
    }
    const { tStatus, icon: Icon } = status
    return { icon: <Icon />, label: t(tStatus, { ns: 'actions' }) }
  }, [visibilityType, t])
  return (
    <div className="flex w-full items-center justify-between bg-white py-2 px-5 md:px-10">
      <div className="flex items-center gap-x-4">
        {print && (
          <div className="relative hidden print:block print:h-8 print:w-16">
            <Image
              src="/img/logo.png"
              alt="logo"
              fill
              className={'object-left-center object-cover lg:object-contain'}
            />
          </div>
        )}
        {showVisibilityStatus && !!visibilityType && (
          <div className="exact-print-color flex items-center rounded bg-red-550 px-4 py-2 uppercase text-white">
            <div className="mr-1">{visibilityAdvertType?.icon} </div>
            {visibilityAdvertType?.label}
          </div>
        )}
      </div>
      <div className="invisible relative h-12 w-24 md:visible">
        {settings?.data.logo.url && (
          <Image
            src={settings?.data.logo.url ?? ''}
            alt={settings?.data.logo.alt ?? '' + ' logo'}
            fill
            className={'object-left-center object-cover lg:object-contain'}
          />
        )}
      </div>
      <div className="flex items-center justify-end">
        <div className="mr-5 font-semibold uppercase">
          {t('companyHeader', { ns: 'ad' })}
        </div>
        <Link href={`/org/${slug}`}>
          <div className="relative h-20 w-20 print:h-12 print:w-12">
            <Image
              src={logoUrl ?? ''}
              alt={brandName ?? '' + ' logo'}
              fill
              className={'object-cover object-right lg:object-contain'}
            />
          </div>
        </Link>
      </div>
    </div>
  )
}

export default AdvertHead
