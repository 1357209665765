import { useMemo } from 'react'
import clsx from 'clsx'

import {
  IconCircleBenefits,
  IconCircleDocument,
  IconCircleHome,
  IconCircleLocation,
  IconCircleRate,
  IconCircleSalary,
  IconDisabilities,
  IconWorkCase
} from 'icons'

import { formatAddress } from 'utils/address.util'
import adUtils from 'utils/adUtil'

import useAdvertTranslation from '../useAdvertTranslation'
import useBonusParser from '../useBonusParser'

import AdvertBullet from './AdvertBullet'
import { IAdvertBulletSectionProps } from './types'

export const AdvertBulletsSection = ({
  bonuses,
  salaryFrom,
  salaryTo,
  contractType,
  remoteWorkType,
  trainingBudget,
  address,
  employmentStatus,
  translation,
  className
}: IAdvertBulletSectionProps) => {
  const { t, lang } = useAdvertTranslation({
    ns: ['ad', 'enums'],
    translation
  })
  const { benefits, disabilities } = useBonusParser({ bonuses, t })

  const advertSalaryTo = salaryTo && salaryFrom <= salaryTo ? salaryTo : null

  const salary = useMemo(
    () =>
      adUtils.getAdvertSalary({
        t,
        from: salaryFrom,
        to: advertSalaryTo
      }),
    [salaryFrom, advertSalaryTo, t]
  )

  return (
    <div className={clsx('bg-white pt-10 pb-10 md:pt-20', className)}>
      <section className="grid grid-cols-1 gap-y-5 gap-x-10 md:grid-cols-2">
        <AdvertBullet
          icon={IconCircleSalary}
          type={t('salary.header', { ns: 'ad' })}
          desc={salary}
        />

        <AdvertBullet
          icon={IconCircleDocument}
          type={t('contractType.header', { ns: 'ad' })}
          desc={contractType ?? 'N/A'}
        />

        <AdvertBullet
          icon={IconCircleHome}
          type={t('remoteWork', { ns: 'ad' })}
          desc={remoteWorkType ?? 'N/A'}
        />

        {!!trainingBudget && (
          <AdvertBullet
            icon={IconCircleRate}
            type={t('trainingBudgetPerYear.header', { ns: 'ad' })}
            desc={trainingBudget ?? 'N/A'}
          />
        )}
        <AdvertBullet
          icon={IconCircleLocation}
          type={t('location.header', { ns: 'ad' })}
          desc={
            !address
              ? t('noOffice', { ns: 'ad' })
              : formatAddress({ ...address, lang })
          }
        />
        {!!benefits.length && (
          <AdvertBullet
            icon={IconCircleBenefits}
            type={t('benefits.header', { ns: 'ad' })}
            desc={benefits}
          />
        )}
        {employmentStatus != null && (
          <AdvertBullet
            icon={IconWorkCase}
            type={t('employmentStatus.title', { ns: 'enums' })}
            desc={t(`employmentStatus.${employmentStatus}`, {
              ns: 'enums'
            })}
          />
        )}
        {disabilities && (
          <AdvertBullet icon={IconDisabilities} desc={disabilities} />
        )}
      </section>
    </div>
  )
}

export default AdvertBulletsSection
