import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'
import { AdvertTranslation } from 'enums'
import { TOptions } from 'i18next'

import { ILocale } from 'types/app'

const useAdvertTranslation = ({
  ns,
  translation
}: {
  ns: string[]
  translation?: AdvertTranslation | null
}) => {
  const { t: tOriginal } = useTranslation(ns)
  const { locale } = useRouter()
  const lang = useMemo(() => {
    switch (translation) {
      case AdvertTranslation.English:
        return 'en'
      case AdvertTranslation.Lithuanian:
        return 'lt'
    }
    return locale as ILocale
  }, [locale, translation])

  const t = useCallback(
    (key: string, options: TOptions) =>
      tOriginal(key, { lng: lang, ...options }),
    [lang, tOriginal]
  )
  return { t, lang }
}

export default useAdvertTranslation
