import { useMemo } from 'react'

import { IUseBonusParserProps } from './types'

const removedBonuses = ['disabilities']

const useBonusParser = ({ bonuses, t }: IUseBonusParserProps) => {
  const benefits = useMemo(() => {
    const benefitsArray: string[] = new Array<string>()

    bonuses &&
      benefitsArray.push(
        ...bonuses
          .filter((item) => !removedBonuses.includes(item))
          .map((x) => t(`benefits.${x}`, { ns: 'ad' }))
      )

    return benefitsArray.join(', ')
  }, [bonuses, t])

  const disabilities = useMemo(
    () =>
      bonuses?.find((item) => item === 'disabilities') &&
      t('benefits.disabilities', { ns: 'ad' }),
    [bonuses, t]
  )

  return { benefits, disabilities }
}

export default useBonusParser
